var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-blacker-layout',{attrs:{"column":""}},[_c('v-blacker-search',{attrs:{"items":_vm.searchFieldsConfig},on:{"reset":_vm.onResetSearch,"search":_vm.onSearch},model:{value:(_vm.searchData),callback:function ($$v) {_vm.searchData=$$v},expression:"searchData"}}),_c('v-blacker-content-box',{attrs:{"is-loading-process":_vm.isLoading,"loading-error":_vm.loadingError,"no-content":_vm.noContent,"go-back-btn":_vm.goBack,"title":"Labels information"}},[_c('v-blacker-table',{attrs:{"headers":_vm.labelsListHeaders,"items":_vm.labels},scopedSlots:_vm._u([{key:"orderId",fn:function(ref){
var orderId = ref.item.orderId;
return [_c('router-link',{staticClass:"blue--text darken-1",attrs:{"to":{
            name: 'order',
            params: { id: orderId },
          }}},[_vm._v(" "+_vm._s(orderId)+" ")])]}},{key:"shipDate",fn:function(ref){
          var shipDate = ref.item.shipDate;
return [_vm._v(" "+_vm._s(_vm.formatDisplayDate(shipDate))+" ")]}},{key:"estimatedDeliveryDate",fn:function(ref){
          var estimatedDeliveryDate = ref.item.estimatedDeliveryDate;
return [_vm._v(" "+_vm._s(_vm.formatDisplayDate(estimatedDeliveryDate))+" ")]}},{key:"actualDeliveryDate",fn:function(ref){
          var actualDeliveryDate = ref.item.actualDeliveryDate;
return [_vm._v(" "+_vm._s(_vm.formatDisplayDate(actualDeliveryDate))+" ")]}}])}),_c('v-blacker-pagination',{attrs:{"items-count":_vm.pagedItemsCount},model:{value:(_vm.pageNum),callback:function ($$v) {_vm.pageNum=$$v},expression:"pageNum"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
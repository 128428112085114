import { mapState, mapActions } from 'vuex';

import { usePagination, useSearch, useDataLoading } from '@mixins/factories';

import { formatDisplayDate } from '@helpers';

import { LABELS_INFORMATION_NAMESPACE, GET_LABELS_INFORMATION } from '../../store.types';

const dataLoadingOptions = { getterName: GET_LABELS_INFORMATION };
const paginationOptions = { itemsName: 'labels' };
const searchOptions = {
  initialSearchDataStates: ['orderId', 'trackingNumber', 'vendor'],
  searchFieldsConfig: [
    { value: 'orderId', label: 'Order ID' },
    { value: 'trackingNumber', label: 'Tracking Number' },
    { value: 'vendor', label: 'Vendor' },
  ],
};
const labelsListHeaders = Object.freeze([
  { text: 'InkRouter ID', value: 'orderId' },
  { text: 'Tracking Number', value: 'trackingNumber' },
  { text: 'Price', value: 'price' },
  { text: 'Actual Ship Date', value: 'shipDate' },
  { text: 'Estimated Delivery Date', value: 'estimatedDeliveryDate' },
  { text: 'Actual Delivery Date', value: 'actualDeliveryDate' },
  { text: 'Shipper Type', value: 'shipperType' },
  { text: 'Service Code', value: 'serviceCode' },
]);

export default {
  name: 'LabelsInformation',
  mixins: [
    useDataLoading(dataLoadingOptions),
    usePagination(paginationOptions),
    useSearch(searchOptions),
  ],
  data: () => ({ labelsListHeaders }),
  computed: {
    ...mapState(LABELS_INFORMATION_NAMESPACE, ['labels']),
  },
  methods: {
    ...mapActions(LABELS_INFORMATION_NAMESPACE, [GET_LABELS_INFORMATION]),
    formatDisplayDate,
  },
};
